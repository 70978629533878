<template>
  <div id="app">
    <div class="l-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style lang="scss" src="./assets/scss/style.scss"></style>
